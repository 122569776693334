import React from 'react';
import { Link } from 'gatsby'
import styles from './styles.module.scss';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const EarnOfOutfit = () => {
  const data = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "earn-of-content.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          className={styles.imageIntro}
          alt="intro"
        />
      </div>
      <dic className={styles.content}>
        <h2 className={styles.title}>Want to earn from your outfits?</h2>
        <p className={styles.description}>
          We partner with all types of content creators and allow you to earn up to 70% of
          revenue when you inspire a purchase.
          <br />
          <br />
          Tap below to sign up on MODE and begin earning!
        </p>
        <Link className={styles.signUpButton} target="_blank" to="/private-beta">Sign Up</Link>
      </dic>
    </div>
  )
}

export default EarnOfOutfit;