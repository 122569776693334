import React from 'react';
import { Link } from 'gatsby'
import styles from './styles.module.scss';
import logoLine from '../../../images/logo-line.svg'
import logo from '../../../images/mode-logo.svg'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Intro = () => {
  const data = useStaticQuery(graphql`
  query {
    logoLine: file(relativePath: { eq: "logo-line.svg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    logo: file(relativePath: { eq: "mode-logo.svg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    introImage: file(relativePath: { eq: "intro.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <section className={styles.intro}>
      <div className={styles.imagesContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.logoNameContainer}>
            <p className={styles.logoText}>
              SHOP
              <br />
              <em>from</em>
              <br />
              SOCIAL
            </p>
            <img
              className={styles.logoLine}
              src={logoLine}
              alt="logo line"
            />
          </div>
          <img
            className={styles.logo}
            src={logo}
            alt="mode-logo"
          />
        </div>
        <div className={styles.imageWrapper}>
          <Img
            className={styles.introImage}
            alt="intro"
            fluid={data.introImage.childImageSharp.fluid}
          />
        </div>
      </div>
      <ul className={styles.stepList}>
        <li className={styles.step}>
          <p className={styles.stepPointer}>1</p>
          <div>
            <span>
              <Link to="/private-beta"><u><b>Sign up</b></u></Link> as a partner and be approved by our team.
            </span>
          </div>
        </li>
        <li className={styles.step}>
          <p className={styles.stepPointer}>2</p>
          <div>
            <span>
              <u><b>Share</b></u> your personalised Shop from Social link in your bio.
            </span>
          </div>
        </li>
        <li className={styles.step}>
          <p className={styles.stepPointer}>3</p>
          <div>
            <span>
              <u><b>Earn</b></u> each time someone shops your content!
            </span>
          </div>
        </li>
      </ul>
    </section>
  )
}

export default Intro;
