import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EarnOfOutfit from "../components/ShopFromSocial/EarnOfOutfit"
import Intro from "../components/ShopFromSocial/Intro"

const ShopFromSocial = () => (
  <Layout>
    <SEO title="Shop from social" />

    <div id='shopFromSocial' className="container mx-auto my-12 px-4 lg:px-8">
      <Intro />
      <EarnOfOutfit />
    </div>
  </Layout>
)

export default ShopFromSocial
